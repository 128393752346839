<script setup lang="ts">
const { isDesktop, isIos } = useDevice();
const title = useState("pageTitle", () => "FLIK");
const keywords = useState("pageKeywords", () => "flik, checkout, nuxt");
const route: any = useRoute();
const trx = useTransactionStore();
const rName: any = route.name || "";
if (rName === "cart") {
  title.value = "Cart";
}
if (rName === "cart-id") {
  const cartMerchant = useCartStore().getMerchantName || "";
  if (cartMerchant !== "") {
    title.value = `Cart - ${cartMerchant}`;
  } else title.value = `Cart Platform`;
}
</script>
<template>
  <Head>
    <Title>{{ title }}</Title>
    <Meta name="description" :content="title" />
    <Meta name="keywords" :content="keywords" />
    <Meta
      v-if="isIos"
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
    />
  </Head>
  <LazyHeaderMerchant class="max-w-full p-[16px]" />
  <section
    id="twoColorHvh"
    class="twoColor relative max-w-full h-full scrollbar-hide"
  >
    <div
      class="relative z-1 h-full"
      :class="[isDesktop ? 'xl:container mx-auto' : '']"
    >
      <slot />
    </div>
    <LazyModalHeadless
      :show="trx.isLoading"
      :line="false"
      :show-close="false"
      :close-outside="false"
      :class-panel="'bg-transparent'"
    >
      <template #info>
        <div class="flex w-full h-full items-center justify-items-center">
          <div class="w-full text-center">
            <LazyIconsLoadingSpinner class="inline text-white" />
            <span class="ml-3 font-medium text-flk-skyblue text-[16px]"
              >Processing transaction...</span
            >
          </div>
        </div>
      </template>
    </LazyModalHeadless>
  </section>
  <LazyFooter
    class="mb-[90px] sm:mb-[160px] md:mb-0 w-full xs:relative xs:z-0 md:fixed md:z-1 md:bottom-0"
  />
  <!-- <LazyFooter class="w-full p-[16px] flex-row" /> -->
</template>
<style scoped>
.twoColor {
  background: linear-gradient(
    to right,
    white 0%,
    white 50%,
    #f9fafb 50%,
    #f9fafb 100%
  );
}
</style>
